import React from 'react';
import Logo from '@/assets/images/logo512.png';
import { useNavigate } from 'react-router-dom';

import { Button } from '../ui/button';

const WelcomeSection: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateToSignup = () => {
    navigate('/signup');
  };

  return (
    <div className="flex items-end md:items-center h-full">
      <img
        className="absolute left-[50%] translate-x-[-50%] top-[12%] max-w-[196px] md:hidden"
        src={Logo}
        alt="batjet-logo"
      />
      <div className="max-w-[450px] mx-auto">
        <h1 className="text-2xl text-center font-bold mb-3 md:text-left md:text-[28px]">Welcome to BATJET</h1>
        <p className="text-sm mb-8 text-secondary text-center md:text-left md:mb-5 md:text-[18px] md:leading-[22px]">
          BatJet is a <span className="font-bold">Managerial Accounting</span> mobile-friendly app that enables you to
          keep track of your expenses and revenues and get real-time insights as to your financial situation.
        </p>
        <Button
          className="mt-4 w-full rounded-[24px] text-base font-semibold h-[44px] md:h-[64px] md:text-[18px]"
          onClick={handleNavigateToSignup}
        >
          SIGN UP
        </Button>
        <Button
          variant="outline"
          className="mt-4 w-full rounded-[24px] text-base font-semibold h-[44px] md:h-[64px] md:text-[18px]"
        >
          I HAVE AN ACCOUNT
        </Button>
      </div>
    </div>
  );
};

export default WelcomeSection;
