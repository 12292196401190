import React, { useState } from 'react';
import { Button } from '../ui/button';
import InputField from '../atoms/InputField';
import { useNavigate } from 'react-router-dom';
import InfoModal from '../molecules/InfoModal';
import isMobile from 'is-mobile';
import { useWidth } from '@/hooks/useWidth';
import PasswordField from '../atoms/PasswordField';
import Checkbox from '../atoms/Checkbox';
import Logo from '@/assets/images/logo512.png';
import loginBrokenImg from '@/assets/images/login-broken.svg';

const LoginForm = () => {
  const [showSuccess, setShowSuccess] = useState(false);

  const navigate = useNavigate();

  const width = useWidth();
  const isMobileView = width < 768 || isMobile() ? true : false;

  const handleTryAgainClick = () => {
    setShowSuccess(false);
  };

  const handleSignInClick = () => {
    setShowSuccess(true);
  };

  const handleOnBoardingClick = () => {
    navigate('/onboarding');
  };

  const handleSignUpClick = () => {
    navigate('/signup');
  };

  return (
    <div
      className={`pb-10 h-full max-w-[616px] mx-auto laptop-h:h-fit md:pr-7 md:pt-8 border md:border-0 ${showSuccess ? 'flex flex-col justify-center' : ''}`}
    >
      {(!showSuccess || isMobileView) && (
        <form
          className={`max-w-[450px] mt-7 gap-5 mx-auto flex flex-col ${isMobileView ? 'justify-between' : 'justify-center'} py-10 px-5 md:px-0 h-full laptop-h:h-fit`}
        >
          <div>
            <img className="max-w-[130px] mx-auto md:hidden" src={Logo} alt="batjet-logo" />
            <h1 className="mt-7 mb-2 text-xl text-center font-bold uppercase md:text-left md:text-[28px]">Sign In</h1>
            <p className="hidden md:block text-lg text-secondary leading-[22.5px]">
              Sign in to access your personalized dashboard and stay updated with the latest features.
            </p>
            <InputField
              className="my-4 mt-5"
              label={'Username / Mail'}
              placeholder="Input here"
              name="email"
              type="text"
            />
            <PasswordField
              className="mb-3"
              label={'Password'}
              description="Password must be consisting minimum 8 characters"
              placeholder="Enter password..."
              name="password"
            />

            <div className="flex items-center justify-between">
              <Checkbox
                name="agreeTerms"
                label={<p className="text-xs font-medium md:text-sm text-secondary leading-[0.9125rem]">Remeber me</p>}
              />
              <a href="#" className="text-xs text-primary font-medium">
                forget your password ?
              </a>
            </div>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleSignInClick}
              className="my-4 w-full rounded-[24px] text-base font-semibold h-[44px] md:h-[64px] md:text-[18px]"
            >
              SIGN IN
            </Button>
            <p className="text-sm md:text-lg text-center">
              Don't have an account?{' '}
              <button onClick={handleSignUpClick} className="text-primary font-bold">
                Sign up
              </button>
            </p>
          </div>
        </form>
      )}

      {showSuccess && !isMobileView && (
        <>
          <h1 className="mt-7 mb-2 text-xl text-center font-bold uppercase md:text-left md:text-[28px]">Sign In</h1>
          <p className="hidden md:block text-lg text-secondary leading-[22.5px]">
            Sign in to access your personalized dashboard and stay updated with the latest features.
          </p>
          <div className="mt-14 w-full flex flex-col items-center justify-center gap-3">
            <img className="w-[144px] h-[144px]" src={loginBrokenImg} alt="success-img" />
            <p className="font-bold text-2xl">You’ve failed to log in 3 times</p>
            <p className="text-secondary text-[18px] leading-[22px] text-center mx-20">
              You need to wait before you can try logging in again, if you are unsure, you can reset your password.
            </p>
          </div>
          <Button
            onClick={handleOnBoardingClick}
            className="mt-[4.25rem] mb-5 rounded-[24px] text-base font-semibold px-6 h-[64px] text-[18px] w-full"
          >
            GO TO ONBOARDING
          </Button>
          <a href="#" className="text-[18px] text-center text-primary font-medium">
            forget your password ?
          </a>
        </>
      )}

      {showSuccess && isMobileView && (
        <InfoModal
          isOpen={showSuccess}
          variant="success"
          title="You’ve failed to log in 3 times"
          message={
            <p>
              You need to wait before you can try logging in again, if you are unsure, you can{' '}
              <a href="#" className="text-primary">
                reset your password
              </a>
              .
            </p>
          }
          buttonText="Try Again"
          onButtonClick={handleTryAgainClick}
          icon={loginBrokenImg}
        />
      )}
    </div>
  );
};

export default LoginForm;
