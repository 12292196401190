import React, { useState } from 'react';
import Logo from '@/assets/images/logo512.png';

import { useNavigate } from 'react-router-dom';

import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { Button } from '../ui/button';
import InputField from '../atoms/InputField';
import PasswordField from '../atoms/PasswordField';
import Checkbox from '../atoms/Checkbox';

// form schema
const schema = z
  .object({
    email: z.string().min(2, 'Username / mail is required'),
    password: z.string().min(8, 'Password must be consisting minimum 8 characters'),
    confirm_password: z.string(),
    agreeTerms: z.boolean().refine((val) => val === true, {
      message: 'You must agree to the terms'
    })
  })
  .refine((data) => data.password === data.confirm_password, {
    message: 'Must be same with password',
    path: ['confirm_password']
  });

type FormData = z.infer<typeof schema>;

const SignUpForm: React.FC = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty }
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: 'onTouched',
    defaultValues: {
      email: '',
      password: '',
      confirm_password: '',
      agreeTerms: false
    }
  });

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    console.log(data);
    navigate('/profile-creation');
  };

  const handleSignInClick = () => {
    navigate('/login');
  };

  return (
    <div className="max-w-[450px] mx-auto flex flex-col justify-center py-10 px-5 md:px-0 h-full laptop-h:h-fit">
      <img className="max-w-[130px] mx-auto md:hidden" src={Logo} alt="batjet-logo" />
      <h1 className="mt-7 mb-2 text-xl text-center font-bold uppercase md:text-left md:text-[28px]">SignUp</h1>
      <p className="hidden md:block text-lg text-secondary leading-[22.5px]">
        Sign up and take the first step towards achieving your goals with BATJET.
      </p>
      <form className="mt-7 flex flex-col gap-5" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          label={'Username / Mail'}
          placeholder="Input here"
          name="email"
          type="text"
          register={register}
          error={errors.email}
        />
        <PasswordField
          label={'Password'}
          description="Password must be consisting minimum 8 characters"
          placeholder="Enter password..."
          name="password"
          register={register}
          error={errors.password}
        />
        <PasswordField
          label={'Confirm Password'}
          placeholder="Enter password..."
          name="confirm_password"
          register={register}
          error={errors.confirm_password}
        />
        <Checkbox
          name="agreeTerms"
          register={register}
          error={errors.agreeTerms}
          label={
            <p className="text-xs md:text-sm text-secondary leading-[0.9125rem]">
              I agree with BATJET{' '}
              <a href="#" className="text-primary">
                Terms of Service
              </a>{' '}
              and acknowledge the{' '}
              <a href="#" className="text-primary">
                Privacy Policy
              </a>
              .
            </p>
          }
        />

        <Button
          disabled={!isValid || !isDirty}
          className="mt-2 w-full rounded-[24px] text-base font-semibold h-[44px] md:h-[64px] md:text-[18px]"
        >
          SIGN UP
        </Button>
        <p className="text-sm md:text-lg text-center">
          Already have an account?{' '}
          <button onClick={handleSignInClick} className="text-primary font-bold">
            Sign in
          </button>
        </p>
      </form>
    </div>
  );
};

export default SignUpForm;
