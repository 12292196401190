import LandingTemplate from '@/components/templates/LandingTemplate';
import SignUpForm from '@/components/organisms/SignUpForm';

const SignUpPage = () => {
  return (
    <LandingTemplate>
      <SignUpForm />
    </LandingTemplate>
  );
};

export default SignUpPage;
