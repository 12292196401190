import React from 'react';
import LandingTemplate from '@/components/templates/LandingTemplate';
import ProfileCreationForm from '@/components/organisms/ProfileCreationForm';

const ProfileCreationPage = () => {
  return (
    <LandingTemplate>
      <ProfileCreationForm />
    </LandingTemplate>
  );
};

export default ProfileCreationPage;
