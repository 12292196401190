import { createBrowserRouter } from 'react-router-dom';
import { Pages } from './types';

import React from 'react';

// import LandingPage from '../pages/Landing'
import LoginPage from '@/pages/Login';
import LandingPage from '@/pages/Landing';
import SignUpPage from '@/pages/SignUp';
import ProfileCreationPage from '@/pages/ProfileCreation';

export const PAGE: Pages = {
  LANDING: {
    path: '/',
    navTitle: 'Landing'
  },
  LOGIN: {
    path: '/login',
    navTitle: 'Login'
  },
  SIGNUP: {
    path: '/signup',
    navTitle: 'SignUp'
  },
  PROFILE_CREATION: {
    path: '/profile-creation',
    navTitle: 'ProfileCreation'
  }
};

export const routes = createBrowserRouter([
  {
    path: PAGE.LANDING.path,
    element: React.createElement(LandingPage) as React.ReactElement
  },
  {
    path: PAGE.LOGIN.path,
    element: React.createElement(LoginPage) as React.ReactElement
  },
  {
    path: PAGE.SIGNUP.path,
    element: React.createElement(SignUpPage) as React.ReactElement
  },
  {
    path: PAGE.PROFILE_CREATION.path,
    element: React.createElement(ProfileCreationPage) as React.ReactElement
  }
]);

export default routes;
