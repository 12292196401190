import React, { useState } from 'react';
import backIcon from '@/assets/icons/back.svg';
import defaultUserImg from '@/assets/images/default-user.png';
import cameraIcon from '@/assets/icons/camera.svg';
import { Button } from '../ui/button';
import UserProfilePicInput from '../molecules/UserProfilePicInput';
import InputField from '../atoms/InputField';
import { useNavigate } from 'react-router-dom';
import InfoModal from '../molecules/InfoModal';
import MultiStepHeader from '../molecules/MultiStepHeader';
import isMobile from 'is-mobile';
import checkSuccessImg from '@/assets/images/check-success.svg';
import { useWidth } from '@/hooks/useWidth';

const ProfileCreationForm = () => {
  const [showSuccess, setShowSuccess] = useState(false);

  const width = useWidth();

  const isMobileView = width < 768 || isMobile() ? true : false;

  const navigate = useNavigate();

  const handleImageChange = (file: File | null) => {
    console.log('Selected file:', file);
  };

  const handleImageRemove = () => {
    console.log('Image removed, performing cleanup');
  };

  const handleBackClick = () => {
    navigate('/signup');
  };

  const handleCreateAccount = () => {
    setShowSuccess(true);
  };

  const handleOnBoardingClick = () => {
    navigate('/onboarding');
  };

  return (
    <div
      className={`pb-10 h-full max-w-[616px] mx-auto laptop-h:h-fit md:pr-7 md:pt-8 ${showSuccess ? 'flex flex-col justify-between' : ''}`}
    >
      <div className="bg-white px-4 py-3 md:hidden">
        <button onClick={handleBackClick} className="text-foreground flex items-center gap-1">
          {' '}
          <img src={backIcon} alt="back-icon" /> <span className="text-xs font-medium">Profile Creation</span>
        </button>
      </div>

      <MultiStepHeader
        currentStep={1}
        totalSteps={3}
        title="PROFILE CREATION"
        description="Create your profile in a few simple steps and start enjoying a customized experience"
      />

      {(!showSuccess || isMobileView) && (
        <form className="px-4 md:px-0 flex flex-col justify-center items-center md:items-start md:mt-4 py-3">
          <UserProfilePicInput
            defaultUserImg={defaultUserImg}
            cameraIcon={cameraIcon}
            onImageChange={handleImageChange}
            onImageRemove={handleImageRemove}
          />
          <div className="w-full mt-6 flex items-start gap-4">
            <InputField label={'Firstname'} placeholder="Firstname..." name="email" type="text" />
            <InputField label={'Lastname'} placeholder="Lastname..." name="email" type="text" />
          </div>
          <div className="w-full flex flex-col items-start gap-x-4 md:flex-row">
            <InputField className="w-full mt-5" label={'Username'} placeholder="Username..." name="email" type="text" />
            <InputField className="w-full mt-5" label={'Mail'} placeholder="Mail..." name="email" type="text" />
          </div>
          <div className="w-full flex flex-col items-start gap-x-4 md:flex-row">
            <InputField
              className="w-full mt-5 md:w-1/2 md:ml-0 md:mr-auto"
              label={'Phone Number'}
              placeholder="Enter phone number..."
              name="email"
              type="text"
            />
            <span></span>
          </div>

          <div className="flex items-center flex-col md:flex-row-reverse w-full justify-between md:mt-[5.4rem]">
            <Button
              type="button"
              onClick={handleCreateAccount}
              className="mt-[5.4rem] mb-5 w-full rounded-[24px] text-base font-semibold h-[44px] md:mb-0 md:mt-0 md:h-[64px] md:text-[18px] md:flex-1"
            >
              CREATE AN ACCOUNT
            </Button>
            <a href="#" className="text-primary font-semibold md:flex-1 md:mt-2" onClick={handleCreateAccount}>
              Skip for now
            </a>
          </div>
        </form>
      )}

      {showSuccess && !isMobileView && (
        <>
          <div className="w-full flex flex-col items-center justify-center gap-2">
            <img className="w-[144px] h-[144px]" src={checkSuccessImg} alt="success-img" />
            <p className="font-bold text-2xl">Account Created</p>
            <p className="text-secondary text-[18px] leading-[22px]">
              You have successfully created an account in BatJet!
            </p>
          </div>
          <Button
            onClick={handleOnBoardingClick}
            className="mt-[5.4rem] mb-5 w-fit rounded-[24px] text-base font-semibold px-6 h-[64px] text-[18px] ml-auto mr-0"
          >
            GO TO ONBOARDING
          </Button>
        </>
      )}

      {showSuccess && isMobileView && (
        <InfoModal
          isOpen={showSuccess}
          variant="success"
          title="Account Created"
          message="You have successfully created an account in BatJet!"
          buttonText="Go to Onboarding"
          onButtonClick={handleOnBoardingClick}
        />
      )}
    </div>
  );
};

export default ProfileCreationForm;
